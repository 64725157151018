/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
  Center,
  Box,
  Text,
  Button,
  Loader,
  Group,
  Tooltip,
  Grid,
  createStyles,
  Card,
  Avatar,
  Tabs,
  Progress,
} from '@mantine/core';
import { recordTypes, roleInitialNoteMap } from '../constants';
import { useMedplum } from '@medplum/react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Socket, io } from 'socket.io-client';
import { getConfig } from '../config';
import {
  getSessionDetails,
  generateSummary,
  updateNote,
  downloadSummaryDetails,
  updateTranscript,
  saveTranscripts,
  completeUnknownPatientSession,
  deleteSessionAndNotes,
  getPractitionerRole,
  getTemplateListAccordingToRole,
  checkSubscriptionExpired,
  submitNoteFeedback,
} from '../utils/util';
import { toast } from 'react-toastify';
import { IconInfoCircle, IconMicrophone, IconCopy, IconMail, IconDownload } from '@tabler/icons-react';
import ConfirmBox from './ConfirmBox';
import { useAppContext } from '../AppProvider';
import EmailBox from './EmailBox';
import TemplateList from './TemplateList';
import PatientStepper from './PatientStepper';
import axios from '../utils/axios';
import UnknownSessionConfirmBox from '../patients/ConfirmBox';
import FeedbackRatingPopup from './FeedbackRatingPopup';
import { useMediaQuery } from '@mantine/hooks';
import RichTextEditorContainer, { useRichTextEditor } from './RichTextEditorContainer';

const useStyles = createStyles((theme) => ({
  section: {
    padding: theme.spacing.md,
  },
}));

export const SessionDetails = () => {
  const medplum = useMedplum();
  const { classes } = useStyles();
  const location = useLocation();
  const { compositionId, generatedNote } = location?.state || {};
  const navigate = useNavigate();
  const appointmentId = useParams().id;
  const [isStartRecordeing, setStartRecordeing] = useState(false);
  const [audioState, setAudioState] = useState(recordTypes.NOTSTARTED);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const intervalRef = useRef<any>(null);
  const [socket, setSocket] = useState<Socket | null>(null);
  const baseUrl = getConfig()?.baseUrl;
  const transcribeUrl = `${baseUrl}transcribe`;
  const [transcripts, setTranscripts] = useState<string[]>(["No transcript"]);
  const [summary, setSummary] = useState<any>(null);
  const [isUpdateTranscripts, setIsUpdateTranscripts] = useState(false);
  const [isSaveUpateClick, setIsSaveUpateClick] = useState(false);
  const [sessionDetails, setSessionDetails] = useState<any>({});
  const [isSummaryUpdated, setIsSummaryUpdated] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [audioUpdate, setAudioUpdate] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [openEmailBox, setOpenEmailBox] = useState(false);
  const {
    setIsSessionOn,
    isConfirmOpen,
    setIsConfirmOpen,
    redirectionUrl,
    setRedirectionUrl,
    setIsSummaryDetailsUpdated,
    isOldPatientId,
    setUnknownSessionsRefresh,
    hasSoapSummaryGenerationError,
    setHasSoapSummaryGenerationError,
    zoomTranscript,
    setIsSegmentedNote,
    isSegmentedNote,
    segmentedNoteTitle,
    setSegmentedNoteTitle,
    setTemplateListBtnDisabled,
    isTemplateListBtnDisabled
  } = useAppContext();
  const [trascriptDocReferenceId, setTrascriptDocReferenceId] = useState<string | null>(null);
  // const [soapSummary, setSoapSummary] = useState('');
  const [summaryNote, setSummaryNotes] = useState('');
  const [selectedButton, setSelectedButton] = useState<string | null>(null);
  const [noteId, setNoteId] = useState('');
  const [isGeneratedTranscript, setIsGeneratedTranscript] = useState<boolean>(false);
  const [isConsentBox, setIsConsentBox] = useState<boolean>(false);
  const [consentBoxEvent, setConsentBoxEvent] = useState<string>('');
  const [isCheckLoader, setIsCheckLoader] = useState<boolean>(false);
  const [isCheckSession, setIsCheckSession] = useState(false);
  const [isCheckActionBtn, setIsCheckActionBtn] = useState(true);
  const [jsxRendered, setJsxRendered] = useState(false);
  const [isCheckLoaderTranscript, setIsCheckLoaderTranscript] = useState(false);
  const [isSaveTranscripts, setIsSaveTranscripts] = useState(false);
  const [isTabsLoad, setIsTabLoad] = useState(true);
  const [isCheckNoteGenerated, setIsCheckNoteGenerated] = useState(false);
  const [isAddPatient, setIsAddPatient] = useState(false);
  const [isCheckNextBtnLoader, setIsCheckNextBtnLoader] = useState<boolean>(false);
  const [defaultPatientEmail, setdefaultPatientEmail] = useState('');
  const [disableButtons, setDisableButtons] = useState(false);
  const [selectedTab, setSelectedTab] = useState('notedetails');
  const isMobile = useMediaQuery('(max-width: 769px)');

  const [unknownSessionConfirm, setUnknownSessionConfirm] = useState(false);
  const [isDeletingLoader, setIsDeletingLoader] = useState(false);
  const [templatesList, setTemplatesList] = useState<any[]>([]);

  const resourceID = medplum.getActiveLogin()?.profile?.reference;
  const parts: any = resourceID?.split('/');
  const practitionerId = parts?.[1];

  const [isNotesTabEnabled, setIsNotesTabEnabled] = useState(true);
  const [subject, setSubjective] = useState('');
  const [objective, setObjective] = useState('');
  const [assessment, setAssessment] = useState('');
  const [plan, setPlan] = useState('');
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [isProgressBarVisible, setIsProgressBarVisible] = useState(false);
  const [popupOpened, setPopupOpened] = useState(false);
  const intervalSoap = useRef<any>(null);
  const startStopTranscriptionTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [emailBoxSoapSection, setEmailBoxSoapSection] = useState<string>('');
  const [emailBoxContent, setEmailBoxContent] = useState('');
  const [isCopyLoading, setIsCopyLoading] = useState(false);
  const [copiedSection, setCopiedSection] = useState<string | null>(null);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [downloadingSection, setDownloadingSection] = useState<string | null>(null);
  const customTextareaRef = useRef<{ getEditorValue: () => string } | null>(null);

  //tab close event to update the transcript
  const transcriptsRef = useRef(transcripts);
  useEffect(() => {
    transcriptsRef.current = transcripts;
  }, [transcripts]);

  useEffect(() => {
    if (isSaveTranscripts || isStartRecordeing) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isSaveTranscripts, isStartRecordeing]);

  const handleBeforeUnload = async (event: BeforeUnloadEvent) => {
    const token = await medplum.getAccessToken();
    const payload = {
      transcriptDocReferenceId: trascriptDocReferenceId,
      transcript: transcriptsRef.current,
    };
    const response = await axios.put(`/intg/update-transcript`, payload, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    console.log(response)
  };

  const [newCompositionId, setnewCompositionId] = useState<string>('');

  useEffect(() => {
    const fetchCompositionId = async () => {
      try {
        const response = await getPractitionerRole(medplum, practitionerId);
        const role = response?.entry?.[0]?.resource?.code?.[0]?.coding?.[0]?.display || '';
        const templateResponse = await getTemplateListAccordingToRole(medplum, role);

        if (templateResponse?.entry?.length >= 0) {
          const defaultTitle = 'Medication Management';
          const title = roleInitialNoteMap[role] || defaultTitle;
          const entries = templateResponse.entry;
          setTemplatesList(entries);
          const entry = entries.find((entry: any) => entry.resource.title === title);
          setnewCompositionId(entry?.resource.id);
        } else {
          toast.error('Error generating SOAP notes from transcript.');
          console.error('Role is not assigned to any template: ', role);
        }
      } catch (error) {
        console.error('Error fetching template list:', error);
      }
    };

    fetchCompositionId();
  }, []);

  useEffect(() => {
    if (templatesList.length > 0) {
      getSession();
      return () => {
        clearInterval(intervalSoap.current);
      };
    }
    return () => { };
  }, [templatesList]);

  const getSession = (isNewTab: boolean = false, tabId: string = '') => {
    setIsTabLoad(false);
    getSessionDetails(medplum, appointmentId)
      .then((response: any) => {
        setTemplateListBtnDisabled(false);
        setdefaultPatientEmail(response?.patientEmail);
        setIsTabLoad(true);
        setIsCheckSession(true);
        setSessionDetails(response);
        setSummary(response?.soapSummary);
        setTranscripts(response?.transcriptData?.data?.transcripts);
        transcriptsRef.current = response?.transcriptData?.data?.transcripts;

        // Find the first item with sectionedData
        const sectionedDataItem = response?.soapSummary?.find(
          (item: any) => item.sectionedData
        );

        if (sectionedDataItem && Object.keys(sectionedDataItem.sectionedData).length) {
          const sectionedData = sectionedDataItem.sectionedData;
          Object.keys(sectionedData).forEach((key) => {
            const value = sectionedData[key] || "";
            const cleanedValue = typeof value === 'string' ? value.replace(/<\/?b>/g, '').trim() : "";
            const formattedValue = cleanedValue.replace(/\n/g, '<br>');
            switch (key) {
              case 'Subjective':
                setSubjective(formattedValue);
                break;
              case 'Objective':
                setObjective(formattedValue);
                break;
              case 'Assessment':
                setAssessment(formattedValue);
                break;
              case 'Plan':
                setPlan(formattedValue);
                break;
              default:
                break;
            }
          });
        }

        if (response?.soapSummary?.length === 0 && hasSoapSummaryGenerationError) {
          updateGeneratedTranscript(response?.transcriptData?.transcriptDocId, appointmentId);
          return;
        } else {
          setHasSoapSummaryGenerationError(false);
        }
        if (generatedNote) {
          setSummaryNotes(generatedNote);
        } else {
          setSummaryNotes(response?.soapSummary[0]?.notes);
        }
        const compositionNames = [] as Array<string>;
        for (let template of templatesList) {
          const code = template.resource?.type?.coding?.[0]?.code || '';
          if (code === 'visit-type') {
            compositionNames.push(template?.resource.title!);
          }
        }

        const visitType = response?.soapSummary?.filter((item: any) =>
          compositionNames.includes(item.compositionName)
        );
        if (!visitType || visitType.length === 0) {
          const entry = templatesList.find((entry: any) => entry.resource.title === compositionNames[0]);
          visitType.push(entry);
        }
        if (compositionId) {
          setSelectedButton(compositionId);
        }

        if (isNewTab === true && !compositionId) {
          console.log(isNewTab);
          const noteId = response?.soapSummary?.find((item: any) => item.compositionId === tabId);
          setNoteId(noteId.noteId || tabId);
          setSummaryNotes(noteId?.notes);
        }
        else {
          setSelectedButton(visitType[0]?.compositionId);
          const noteId = response?.soapSummary?.find((item: any) => item.compositionId === compositionId);
          setNoteId(noteId?.noteId || response?.soapSummary[0]?.noteId);
        }

        setTrascriptDocReferenceId(response?.transcriptData.transcriptDocId);
        if (response?.soapSummary?.length === 0 && response?.patientnName !== "unknown") {
          //toast.info('Notes are being generated. Please check back in a bit. Click the Notes tab to refresh.');
          setIsNotesTabEnabled(false);
          setIsProgressBarVisible(true);
          setProgressPercentage(0);
          const progressInterval = setInterval(() => {
            setProgressPercentage(prev => {
              if (prev < 90) {
                return prev + 2;
              } else {
                clearInterval(progressInterval);
                return prev;
              }
            });
          }, 1000);

          const maxAttempts = 3;
          let attempts = 0;
          intervalSoap.current = setInterval(() => {
            attempts++;
            getSessionDetails(medplum, appointmentId).then((updatedResponse: any) => {
              if (updatedResponse?.soapSummary?.length > 0) {
                setHasSoapSummaryGenerationError(false);
                // Notes have been generated
                setSummary(updatedResponse.soapSummary);
                setSummaryNotes(updatedResponse.soapSummary[0]?.notes);
                setIsNotesTabEnabled(true);
                setProgressPercentage(100);
                getSession();

                // Hide progress bar after 5 seconds
                setTimeout(() => {
                  setIsProgressBarVisible(false);
                  clearInterval(intervalSoap.current);
                }, 5000);
              } else if (attempts >= maxAttempts || hasSoapSummaryGenerationError) {
                // Stop the interval after maxAttempts or error to generate notes
                clearInterval(intervalSoap.current);
                clearInterval(progressInterval);
                setIsNotesTabEnabled(false);
                setIsProgressBarVisible(true);
                setProgressPercentage(0);

                const reAttemptInterval = setInterval(() => {
                  setProgressPercentage(prev => {
                    if (prev < 90) {
                      return prev + 10;
                    } else {
                      clearInterval(reAttemptInterval);
                      return prev;
                    }
                  });
                }, 3000);
                updateGeneratedTranscript(updatedResponse?.transcriptData?.transcriptDocId, appointmentId);
              }
            });
          }, 6000);
        }
      })
      .catch((error: any) => {
        setTemplateListBtnDisabled(false);
        console.error('Error fetching data:', error);
        toast.error('Failed to fetch session details.');
      });
  };

  const startTranscription = async () => {
    //added to check login session is expired or not
    try {
      await checkSubscriptionExpired(medplum)
    } catch (error) {
      toast.error('Your session has expired. Please sign-in again.');
      setTimeout(async () => {
        stopTranscription();
        await medplum.signOut();
        navigate('/signin');
        setIsSessionOn(false);
      }, 1500);
      return;
    }
    setIsSessionOn(true);
    const socket = io(transcribeUrl, { transports: ['polling'] });
    setSocket(socket);
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setAudioState(recordTypes.STARTED);
        console.log(audioState);
        setStartRecordeing(true);
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        socket.on('can-open-mic', () => {
          console.log('Opening mic...');
          if (mediaRecorder && mediaRecorder.state === 'inactive') {
            mediaRecorder.start(2000);
          }
        });
        mediaRecorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            socket.emit('microphone-stream', e.data);
          }
        };
        socket.on('close-mic', () => {
          console.log('Closing mic');
          mediaRecorder.stop();
          socket.disconnect();
        });
        socket.on('transcript-result', (socketId, jsonFromServer) => {
          const result = jsonFromServer;
          if (result) {
            updateTranscripts(result);
          }
        });
        socket.on('disconnect', () => {
          console.log('Disconnected from server');
          mediaRecorder.stop();
          socket.disconnect();
        });
      })
      .catch((error) => {
        console.error('Error accessing microphone:', error);
      });
  };

  const updateTranscripts = (transcript: string) => {
    setTranscripts((prevTranscripts) => {
      if (!Array.isArray(prevTranscripts)) {
        prevTranscripts = [];
      }
      return [...prevTranscripts, transcript];
    });
    setAudioUpdate(true);
  };

  const stopTranscription = () => {
    setAudioState(recordTypes.FINISHED);
    startStopTranscriptionTimer(false);
    setStartRecordeing(false);
    setIsSessionOn(false);
    socket?.disconnect();
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      clearInterval(intervalRef.current);
      setStartRecordeing(false);

      // Access the stream and stop all tracks to disconnect the microphone
      const tracks = mediaRecorderRef.current.stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });

      if (transcripts?.length) {
        setIsUpdateTranscripts(true);
        setIsSummaryDetailsUpdated(true);
        setIsSaveTranscripts(true);
      }
    }
  };

  const transcriptData = transcripts?.length > 0 ? transcripts : (zoomTranscript?.length > 0 ? zoomTranscript : []);
  const getSummary = (data?: any) => {
    const payload = {
      compositionId: [data?.id],
      trascriptDocReferenceId: trascriptDocReferenceId,
      patientId: sessionDetails?.patientId,
      practitionerId: sessionDetails?.practitionerId,
      appointmentId: sessionDetails?.appointmentId,
      encounterId: sessionDetails?.encounterId,
      transcript: transcriptData,
    };
    setSelectedButton(data?.resource?.id);
    setIsLoading(true);
    toast.success('Notes will be generated in the background.');
    generateSummary(medplum, payload)
      .then(() => {
        toast.success('Notes generated successfully.', { autoClose: 2000 });
        setIsLoading(false);
        setIsUpdateTranscripts(false);
        setSelectedButton(data?.id);
        getSession(true, data.id);
        // setSoapSummary(response.data?.soapSummary?.notes);
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.error('Error :', error);
        toast.error('Failed to generate the summary.');
      });
  };

  const updateSummary = () => {
    let updatedNote = customTextareaRef?.current?.getEditorValue() || '';
    let sectionedData = {};

    if (segmentedNoteTitle && segmentedNoteTitle.includes("SOAP")) {
      sectionedData = {
        Subjective: subject,
        Objective: objective,
        Assessment: assessment,
        Plan: plan,
      };

      if (updatedNote === '') {
        updatedNote = '';

        if (subject) {
          updatedNote += 'Subjective' + '\n' + subject + '\n';
        }
        if (objective) {
          updatedNote += 'Objective' + '\n' + objective + '\n';
        }
        if (assessment) {
          updatedNote += 'Assessment' + '\n' + assessment + '\n';
        }
        if (plan) {
          updatedNote += 'Plan' + '\n' + plan + '\n';
        }
      }
    }


    const payload = {
      noteId: noteId,
      updatedNote: updatedNote,
      ...(segmentedNoteTitle.includes("SOAP") && { sectionedData: sectionedData }),
    };

    setIsLoading(true);
    updateNote(medplum, payload)
      .then((response) => {
        setIsLoading(false);
        toast.success(response.message);
        setIsSummaryUpdated(false);
        setIsSummaryDetailsUpdated(false);
        // Update the summary state with the latest notes
        setSummary((prevSummary: any[]) =>
          prevSummary.map((item) =>
            item.noteId === noteId ? { ...item, notes: updatedNote } : item
          )
        );
        // Update the editor content with the latest notes
        setSubjective(subject);
        setObjective(objective);
        setAssessment(assessment);
        setPlan(plan);

        window.location.reload();
      })
      .catch((error: Error) => {
        setIsLoading(false);
        console.error('Error on updating Note:', error);
        toast.error('Failed to update the Note.');
      });
  };

  useEffect(() => {
    if (jsxRendered && Array.isArray(transcripts)) {
      const divElement = document.getElementById(`transcripts_data`);
      if (divElement) {
        divElement.innerHTML = '';
        transcripts.forEach((item: string) => {
          const newDiv = document.createElement('div');
          newDiv.innerText = item;
          newDiv.className = `transcripts_item`;
          divElement.appendChild(newDiv);
        });
        setAudioUpdate(false);
      }
    }
  }, [audioUpdate, jsxRendered]);


  const handleDivInput = (event: React.FormEvent<HTMLDivElement>) => {
    const text = event.currentTarget.innerText || event.currentTarget.textContent;
    const contentArray = text?.split('\n') || [];
    setIsUpdateTranscripts(true);
    setIsSaveTranscripts(true);
    setIsSummaryDetailsUpdated(true);
    setTranscripts(contentArray);
  };

  const downloadSummary = (soapSection?: string, content?: string) => {
    setIsDownloading(true);
    if (noteId) {
      downloadSummaryDetails(medplum, noteId, content ? content : summaryNote, soapSection || "",)
        .then((response: any) => {
          setDownloadingSection(null);
          if (response.data) {
            const filename = response?.headers['content-disposition']?.split('filename=')?.[1];
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${filename}`;
            a.click();
            window.URL.revokeObjectURL(url);
            setIsDownloading(false);
            toast.success('Notes Downloaded Successfully.');
          }
        })
        .catch((error: any) => {
          setIsDownloading(false);
          setDownloadingSection(null);
          console.error('Error :', error);
          toast.error('Download failed.');
        });
    }
  };

  const onConfirmExit = async () => {
    stopTranscription();
    setTranscripts([]);
    setIsSummaryDetailsUpdated(false);

    if (redirectionUrl) {
      if (redirectionUrl === '/signin') {
        await medplum.signOut();
        setIsConfirmOpen(false);
        navigate(redirectionUrl);
        setRedirectionUrl(undefined);
      } else {
        setIsConfirmOpen(false);
        navigate(redirectionUrl);
        setRedirectionUrl(undefined);
      }
    } else {
      setIsConfirmOpen(false);
      navigate('/session');
      setRedirectionUrl(undefined);
    }
  };

  const copyDetails = (summary: string) => () => {
    setIsCopied(true);
    const plainText = summary
      .replace(/<br\s*\/?>/gi, '\n')
      .replace(/<\/?[^>]+(>|$)/g, "");
    navigator.clipboard
      .writeText(plainText)
      .then(() => {
        console.log('Copied to clipboard');
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((error) => {
        console.error('Error on copying:', error);
        setIsCopied(false);
      });
  };

  const handleClickTemplate = (data: any) => {
    setIsSegmentedNote(false);
    setSummaryNotes(data.notes);
    setSelectedButton(data.compositionId);
    setNoteId(data.noteId);
    setSegmentedNoteTitle(data.compositionName);
  };

  const updateGeneratedTranscript = (transcriptId?: any, appointmentId?: any) => {
    //adding check means updating transcript only if notes are not generated
    //and trying to reattempt to generate notes
    setIsSaveUpateClick(true);
    startStopTranscriptionTimer(false);
    toast.info('Notes are being generated. Please wait...');
    const payload = {
      transcriptDocReferenceId: trascriptDocReferenceId ?? transcriptId,
      transcript: transcriptsRef.current,
      appointmentId: sessionDetails?.appointmentId ?? appointmentId,
    };

    setIsCheckLoader(true);
    setIsGeneratedTranscript(false)
    setIsCheckNoteGenerated(true);
    updateTranscript(medplum, payload)
      .then((response: any) => {
        setIsSaveUpateClick(false);
        setIsCheckLoader(false);
        setIsUpdateTranscripts(false);
        setIsConfirmOpen(false);
        setIsSessionOn(false);
        setIsSaveTranscripts(false);
        setIsSummaryDetailsUpdated(false);
        setIsCheckNoteGenerated(false);
        setIsProgressBarVisible(false);
        setProgressPercentage(0);
        if (response?.note?.length > 0) {
          toast.success(response.message, { autoClose: 2000 });
          getSession();
        } else {
          toast.error(`Note generation was unsuccessful. Please use 'Save & Update Notes' button to regenerate the note again.`);
        }
      })
      .catch((error: any) => {
        const errorMessage = error?.response?.status === 503 && error?.response?.data
          ? error.response.data
          : 'Note generation was unsuccessful. Please try to update transcript again.';
        toast.error(errorMessage, { autoClose: 30000 });

        setIsProgressBarVisible(false);
        setIsUpdateTranscripts(false);
        setIsGeneratedTranscript(false);
        setIsCheckLoader(false);
      });
  };

  const handleClickTabs = (value: string) => {
    setSelectedTab(value);
    setSelectedButton(null);
    setIsCheckActionBtn(value === 'notedetails');
    if (isCheckNoteGenerated && value === 'notedetails' && sessionDetails?.patientnName !== "unknown") {
      //toast.success('Notes are being generated. Please check back in a bit. Click the Notes tab to refresh.');
      setIsNotesTabEnabled(false);
      setIsProgressBarVisible(true);
      setProgressPercentage(0);
      const progressInterval = setInterval(() => {
        setProgressPercentage(prev => {
          if (prev < 90) {
            return prev + 10;
          } else {
            clearInterval(progressInterval);
            return prev;
          }
        });
      }, 1000);

      const maxAttempts = 2
      let attempts = 0;
      const checkNotesInterval = setInterval(() => {
        attempts++;
        getSessionDetails(medplum, appointmentId).then((updatedResponse: any) => {
          if (updatedResponse?.soapSummary?.length > 0) {
            // Notes have been generated
            setSummary(updatedResponse.soapSummary);
            setSummaryNotes(updatedResponse.soapSummary[0]?.notes);
            setIsNotesTabEnabled(true);
            setProgressPercentage(100);

            // Hide progress bar after 5 seconds
            setTimeout(() => {
              setIsProgressBarVisible(false);
              clearInterval(checkNotesInterval);
            }, 5000);
          } else if (attempts >= maxAttempts) {
            // Stop the interval after maxAttempts
            clearInterval(checkNotesInterval);
          }
        });
      }, 5000);

    }
  };

  const parseHTMLToPlainText = (html: string) => {
    return html?.replace(/<\/?b>/g, '');
  }

  const saveTranscript = () => {
    const payload = {
      transcriptDocReferenceId: trascriptDocReferenceId,
      transcript: transcriptsRef.current,
    };
    setIsCheckLoaderTranscript(true);
    saveTranscripts(medplum, payload).then((response) => {
      setIsSaveTranscripts(false);
      setIsCheckLoaderTranscript(false);
      setIsConfirmOpen(false);
      setIsSessionOn(false);
      setIsSummaryDetailsUpdated(false);
      toast.success(response.message, { autoClose: 2000 });
    }).catch((error) => {
      console.log(error);
      setIsCheckLoaderTranscript(false);
      setIsSaveTranscripts(false);
      setIsConfirmOpen(false);
      setIsSessionOn(false);
      setIsSummaryDetailsUpdated(false);
    });
  }

  const handleEndSessionClick = () => {
    setIsAddPatient(true);
    setDisableButtons(true);
  };

  const onCancel = () => {
    setIsAddPatient(false);
  };

  const getSessionResp = (appointmentId: string) => {
    getSessionDetails(medplum, appointmentId)
      .then((response: any) => {
        getSummaryResp(response);
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
        toast.error('Failed to fetch session details.');
      });
  };

  const getSummaryResp = (response?: any) => {
    const payload = {
      compositionId: [newCompositionId],
      trascriptDocReferenceId: response?.transcriptData?.transcriptDocId,
      patientId: response?.patientId,
      practitionerId: response?.practitionerId,
      appointmentId: response?.appointmentId,
      encounterId: response?.encounterId,
      transcript: transcripts,
    };
    generateSummary(medplum, payload)
      .then(() => {
        setIsCheckNextBtnLoader(false);
      })
      .catch((error: any) => {
        console.error('Error :', error);
        toast.error('Failed to generate the summary.');
      });
  }

  const endUnknownSession = async (patientData: any) => {
    setIsCheckNextBtnLoader(true);
    let navigateUrl: string | undefined;
    const { transcripts } = sessionDetails.transcriptData.data || { transcripts: [] };
    const payload = {
      appointmentId,
      patientName: patientData.patientDetails?.patientName,
      birthDate: patientData.patientDetails?.birthDate,
      transcripts,
      mentalHealthDiagnoses: patientData?.patientDetails?.mentalHealthDiagnoses,
      otherDiagnoses: patientData?.patientDetails?.otherDiagnoses,
      patientId: isOldPatientId,
    };

    try {
      const response = await completeUnknownPatientSession(medplum, payload);
      setIsAddPatient(false);
      setIsCheckNextBtnLoader(false);
      if (response) {
        navigateUrl = `/Appointment/${response.sessionId}/details`;
        getSessionResp(response.sessionId);
        toast.success('Session completed successfully.');
        setTimeout(() => {
          if (navigateUrl) {
            navigate(navigateUrl);
          } else {
            navigate('/session');
          }
          window.location.reload();
        }, 1000);
      } else {
        toast.error('Notes will not be generated.', {
          onClose: () => navigate('/session')
        });
      }
    } catch (error) {
      toast.error('Error in completing the session. Please try again.');
      setIsCheckNextBtnLoader(false);
      setIsAddPatient(false);
      console.error('API Error:', error);
    }
  };

  const handleConfirmDelete = async () => {
    setIsDeletingLoader(true);
    setDisableButtons(true);
    setUnknownSessionsRefresh(false);
    try {
      const path = window.location.pathname;
      const id = path.split('/').slice(-2, -1)[0];

      if (!id) {
        throw new Error('ID not found in URL');
      }
      const deleteResponse = deleteSessionAndNotes;
      deleteResponse(medplum, id)
        .then((response) => {
          if (response) {
            toast.success(`Session Deleted successfully!`, {
              position: toast.POSITION.TOP_RIGHT
            });
            setTimeout(() => {
              setDisableButtons(false);
              navigate('/session');
            }, 2000);
            setUnknownSessionsRefresh(true);
            setIsDeletingLoader(false);
            setUnknownSessionConfirm(false);
          }
        })
        .catch((error) => {
          console.error('Error deleting session:', error);
          toast.error(`Failed to delete the session`, {
            position: toast.POSITION.TOP_RIGHT
          });
          setDisableButtons(false);
          setIsDeletingLoader(false);
          setUnknownSessionConfirm(false);
        });
    } catch (error) {
      console.error(`Error deleting session:`, error);
      toast.error(`Failed to delete the session`, {
        position: toast.POSITION.TOP_RIGHT
      });
      setIsDeletingLoader(false);
    }
  };

  // const handleRegenerateClick = () => {
  //   const data = { 'id': selectedButton };
  //   getSummary(data);
  // };

  const handleFeedbackClick = () => {
    setPopupOpened(true);
  };

  const handlePopupClose = () => {
    setPopupOpened(false);
  };

  const handlePopupSubmit = async (feedback: string[], comment: string, rating: string) => {
    const payload = {
      noteId: noteId,
      rating: rating,
      comment: feedback + (comment ? `: ${comment}` : ''),
    };

    try {
      await submitNoteFeedback(medplum, payload);
      toast.success('Feedback submitted successfully');
    } catch (error) {
      toast.error('Error submitting feedback');
    }
  };

  // Set up a timer to save transcription periodically every 30 minutes
  function startStopTranscriptionTimer(isStart: boolean) {
    if (isStart) {
      if (startStopTranscriptionTimerRef.current) {
        clearInterval(startStopTranscriptionTimerRef.current);
        startStopTranscriptionTimerRef.current = null;
      }

      startStopTranscriptionTimerRef.current = setInterval(async () => {
        console.log('Saving transcription...');
        console.log(transcripts);
        await saveTranscript();
        //added to check if the login session is expired
        await isLoginSessionExpired();
      }, 10 * 60 * 1000);
    } else {
      if (startStopTranscriptionTimerRef.current) {
        clearInterval(startStopTranscriptionTimerRef.current);
        startStopTranscriptionTimerRef.current = null;
      }
    }
  }

  const isLoginSessionExpired = async () => {
    try {
      await checkSubscriptionExpired(medplum);
    } catch (error) {
      if (!transcripts?.length) {
        await saveTranscript();
      }
      toast.error('Your session has expired. Please sign-in again.');
      setTimeout(async () => {
        stopTranscription();
        await medplum.signOut();
        navigate('/signin');
        setIsSessionOn(false);
      }, 1500);
      return;
    }
  };

  //entire SOAP section copy , download and send email functionality
  const handleSectCopy = async (sectionedData: any, section: string) => {
    setIsCopyLoading(true);
    try {
      const { Subjective, Objective, Assessment, Plan } = sectionedData;
      const combinedContent = `
Subjective
${Subjective || ''}
\nObjective
${Objective || ''}
\nAssessment
${Assessment || ''}
\nPlan
${Plan || ''}
        `;
      const plainText = combinedContent
        .replace(/<br\s*\/?>/gi, '\n')
        .replace(/<\/?[^>]+(>|$)/g, "");
      await navigator.clipboard.writeText(plainText);
      setCopiedSection('all');
      setTimeout(() => setCopiedSection(null), 2000);
    } finally {
      setIsCopyLoading(false);
    }
  };

  const handleDownloadEntireNote = async () => {
    const entireNoteContent = `
      Subjective \n ${subject} \n
      Objective \n ${objective} \n
      Assessment \n ${assessment} \n
      Plan \n ${plan} \n
    `;
    setIsDownloading(true);
    setDownloadingSection("SOAP Download");
    downloadSummary("", entireNoteContent);
  };

  const handleSendEntireNoteEmail = async () => {
    const entireNoteContent = `
Subjective\n ${subject}\n
Objective\n ${objective}\n
Assessment\n ${assessment}\n
Plan\n ${plan}
    `;
    setIsConsentBox(true);
    setConsentBoxEvent(`email||${entireNoteContent}`);
  };
  //Individual Sections
  const subjectEditor = useRichTextEditor(subject, setSubjective, setIsSummaryUpdated);
  const objectiveEditor = useRichTextEditor(objective, setObjective, setIsSummaryUpdated);
  const assessmentEditor = useRichTextEditor(assessment, setAssessment, setIsSummaryUpdated);
  const planEditor = useRichTextEditor(plan, setPlan, setIsSummaryUpdated);

  const handleEmailClick = (label: string, content: string) => {
    setIsConsentBox(true);
    setEmailBoxSoapSection(label);
    setConsentBoxEvent(`email|${label}|${content}`);
    setEmailBoxContent(content);
  };

  const handleCopy = async (value: string, section: string) => {
    setIsCopyLoading(true);
    try {
      const plainText = value.replace(/<br\s*\/?>/gi, '\n').replace(/<\/?[^>]+(>|$)/g, "");
      await navigator.clipboard.writeText(plainText);
      setCopiedSection(section);
      setTimeout(() => setCopiedSection(null), 2000);
    } finally {
      setIsCopyLoading(false);
    }
  };

  const handleDownload = async (label: string, content: string) => {
    setIsDownloadLoading(true);
    try {
      setDownloadingSection(label);
      downloadSummary(label, content);
    } finally {
      setIsDownloadLoading(false);
    }
  };

  //Complete Note for non segmented notes
  const CustomTextarea = forwardRef(({
    label,
    initialValue,
    isCheckActionBtn,
    copyDetails,
    isloading,
    isCopied,
    isDownloading,
    downloadSummary,
    setIsConsentBox,
    setConsentBoxEvent,
  }: {
    label: string;
    initialValue: string;
    isCheckActionBtn: boolean;
    copyDetails: (value: string) => () => void;
    isloading: boolean;
    isCopied: boolean;
    isDownloading: boolean;
    isEmailLoading: boolean;
    downloadSummary: () => void;
    setIsConsentBox: (value: boolean) => void;
    setConsentBoxEvent: (event: string) => void;
  }, ref: any) => {
    const [internalValue, setInternalValue] = useState(initialValue);

    const formattedValue = (internalValue || "").replace(/\n/g, '<br>');

    const editor = useRichTextEditor(formattedValue, setInternalValue, setIsSummaryUpdated);

    useEffect(() => {
      setInternalValue(initialValue);
    }, [initialValue]);

    // Expose the internalValue to the parent component
    useImperativeHandle(ref, () => ({
      getEditorValue: () => internalValue,
    }));

    useEffect(() => {
      if (editor) {
        editor.setEditable(true);
      }
    }, [editor]);

    return (
      <RichTextEditorContainer
        label={label}
        value={internalValue}
        editor={editor}
        isCheckActionBtn={isCheckActionBtn}
        section={label}
        handleCopy={copyDetails(internalValue)}
        handleDownload={downloadSummary}
        copiedSection={isCopied ? label : null}
        isCopyLoading={isProgressBarVisible || isloading}
        downloadingSection={isDownloading ? label : null}
        isDownloadLoading={isDownloading || isProgressBarVisible || isloading}
        isEmailLoading={isProgressBarVisible || isloading}
        handleEmailClick={(label) => {
          setIsConsentBox(true);
          setConsentBoxEvent('email');
        }}
      />
    );
  });

  return (
    <>
      <Box px="lg" pb="sm">
        <Grid>
          <Grid.Col span={12} md={12} mt={8}>
            <Card
              shadow="sm"
              sx={{ overflow: 'auto', border: '1px solid #ebebeb', borderRadius: '10px' }}
            >
              <Card.Section className={classes.section} p="lg" style={{ borderBottom: '0px !important' }}>
                <Grid>
                  <Grid.Col lg={1} className="avt-section">
                    <Avatar
                      src=""
                      radius="xl"
                      size="md"
                      sx={{ display: 'block', margin: 'auto' }}
                    />
                  </Grid.Col>
                  <Grid.Col lg={11} className="profile-section">
                    <Grid>
                      <Grid.Col lg={3} pl={40}>
                        <Text component="h6" className="label">
                          Patient Name
                        </Text>
                        <Text component="span">{sessionDetails?.patientnName || '-'}</Text>
                      </Grid.Col>
                      <Grid.Col lg={4} pl={40}>
                        <Text component="h6" className="label">
                          Appointment Date & Time
                        </Text>
                        <Text component="span">
                          {sessionDetails?.appointmentDate
                            ? sessionDetails?.appointmentDate
                            : '-'}
                        </Text>
                      </Grid.Col>
                      <Grid.Col lg={4} pl={40}>
                        <Text component="h6" className="label">
                          No. of Notes Generated
                        </Text>
                        <Text component="span">{summary?.length || '-'}</Text>
                      </Grid.Col>
                    </Grid>
                  </Grid.Col>
                </Grid>
              </Card.Section>
            </Card>
          </Grid.Col>
        </Grid>
      </Box>
      <Box px="lg" pb="sm" mt={20}>
        <Grid>
          {isProgressBarVisible && (
            <Grid.Col md={2} mb={12}>
              <Text>Generating Note</Text>
              <Progress
                size="xl"
                label={`${progressPercentage}%`}
                value={progressPercentage}
                color={progressPercentage === 100 ? 'green' : 'blue'}
              />
            </Grid.Col>
          )}
        </Grid>

        <Grid>
          <Grid.Col span={12} md={8} mt={8}>
            {sessionDetails?.patientnName && (
              <Tabs defaultValue={sessionDetails?.patientnName === "unknown" ? "transcript" : "notedetails"}>
                <Center w="100%" style={{ justifyContent: 'space-between' }}>
                  <Tabs.List sx={{ borderBottom: 'none' }}>
                    {sessionDetails?.patientnName !== "unknown" && (
                      <Tabs.Tab
                        className='customTooltip'
                        value="notedetails"
                        disabled={!isNotesTabEnabled || isCheckLoader}
                        fw={600}
                        onClick={() => handleClickTabs('notedetails')}>
                        Notes
                        <Tooltip
                          label="You can edit the notes directly without changing the transcript. If you want to regenerate the notes, update the transcript first."
                          withArrow
                          position="right"
                          sx={{ fontSize: '12px', fontWeight: 'normal' }}
                        >
                          <span style={{ marginLeft: '5px', cursor: 'pointer' }}>
                            <IconInfoCircle color='#444444d9' size={16} />
                          </span>
                        </Tooltip>
                      </Tabs.Tab>
                    )}
                    <Tabs.Tab className='customTooltip' value="transcript" fw={600} onClick={() => handleClickTabs('transcript')}>Transcript
                      <Tooltip
                        label=" You can edit or update the transcript, and once the recording is done, you can either save the transcript or update it to regenerate the note."
                        withArrow
                        position="right"
                        className='customTooltipStyle'
                        sx={{ fontSize: '12px' }}
                      >
                        <span style={{ marginLeft: '5px', cursor: 'pointer' }}>
                          <IconInfoCircle color='#444444d9' size={16} />
                        </span>
                      </Tooltip>
                    </Tabs.Tab>
                  </Tabs.List>
                </Center>
                {isCheckSession && (
                  <Tabs.Panel value="transcript">
                    <div ref={(ref) => ref && setJsxRendered(true)}>
                      <Grid>
                        <Grid.Col span={12} md={12} mt={20}>
                          <Card
                            shadow="sm"
                            sx={{ overflow: 'auto', border: '1px solid #ebebeb', borderRadius: '10px' }}
                          >
                            <Card.Section className={classes.section} p="lg" style={{ borderBottom: '0px !important' }}>
                              <Grid mt={10} mb={6} sx={{ display: 'block' }}>
                                <Text className="f-dark" fw={700} ml={9} mb={6} sx={{ fontSize: '18px' }}>
                                  Transcript
                                </Text>
                                <Box className="soap-field" ml={10} style={{ minHeight: '400px' }}>
                                  <div
                                    className="text-area"
                                    id="transcripts_data"
                                    contentEditable={!isloading}
                                    onInput={handleDivInput}
                                  ></div>
                                </Box>
                              </Grid>
                            </Card.Section>
                          </Card>
                        </Grid.Col>
                      </Grid>
                      <Center w="100%" mt="lg" className='transcriptBtn'>
                        {sessionDetails?.patientnName === 'unknown' && (
                          <Tooltip
                            label="End unassigned session and assign them to a patient."
                            withArrow
                            position="top"
                            sx={{ fontSize: '12px', maxWidth: '170px', whiteSpace: 'normal', overflowWrap: 'break-word', textAlign: 'left' }}
                          >
                            <Button
                              className="btn-primary"
                              mr="lg"
                              onClick={handleEndSessionClick}
                              disabled={disableButtons || isStartRecordeing}
                            >
                              End Unknown Session
                            </Button>
                          </Tooltip>
                        )}
                        {!isStartRecordeing ? (
                          <Button
                            className="btn-primary"
                            mr="lg"
                            onClick={() => {
                              startStopTranscriptionTimer(true);
                              startTranscription();
                            }}
                            disabled={disableButtons}
                          >
                            Record More <IconMicrophone size={15} style={{ marginLeft: '5px' }} />
                          </Button>
                        ) : (
                          <Group>
                            <Button
                              className={`btn-danger ${audioState === recordTypes.STARTED && 'active-recording'}`}
                              h={20}
                              w={20}
                              p="2px"
                              style={{ background: 'red', borderRadius: '50%' }}
                            >
                              <IconMicrophone size={14} color="white" />
                            </Button>
                            <Button
                              className="btn-primary"
                              mr="lg"
                              onClick={() => {
                                startStopTranscriptionTimer(false);
                                stopTranscription();
                              }}
                            >
                              Stop
                            </Button>
                          </Group>
                        )}
                        <Button
                          className="btn-primary"
                          mr="lg"
                          onClick={saveTranscript}
                          disabled={!isSaveTranscripts || disableButtons}
                        >
                          {isCheckLoaderTranscript ? <Loader size={20} /> : 'Save Transcript'}
                        </Button>

                        {sessionDetails?.patientnName === 'unknown' && (
                          <Button
                            className="btn-danger"
                            mr="lg"
                            onClick={() => setUnknownSessionConfirm(true)}
                            disabled={disableButtons || isStartRecordeing}
                          >
                            Delete Session
                          </Button>
                        )}

                        {sessionDetails?.patientnName !== 'unknown' && (
                          <Button
                            className="btn-primary"
                            mr="lg"
                            onClick={updateGeneratedTranscript}
                            disabled={!isUpdateTranscripts || isSaveUpateClick}
                          >
                            {isCheckLoader ? (
                              <>
                                Updating Notes...
                                <Loader size={20} ml={10} />
                              </>
                            ) : (
                              'Save & Update Notes'
                            )}
                          </Button>
                        )}
                      </Center>
                    </div>
                  </Tabs.Panel>
                )}
                <Tabs.Panel value="notedetails">
                  <Grid className="template-tabs">
                    <Grid.Col span={12} md={12} mt={8}>
                      {isTabsLoad && (
                        <Tabs
                          defaultValue={selectedButton}
                          style={{ margin: '15px 0px', padding: 'calc(1rem / 2)' }}
                        >
                          <Tabs.List sx={{ borderBottom: 'none' }}>
                            {summary?.map((data: any) => (
                              <Tooltip label={data.compositionName} position="top" key={data.compositionId}>
                                <Tabs.Tab
                                  value={data.compositionId}
                                  onClick={() => handleClickTemplate(data)}
                                  disabled={isTemplateListBtnDisabled}
                                  sx={{ width: `${summary.length > 6 ? '100px' : 'auto'}`, overflow: 'hidden', textOverflow: 'clip', display: 'block !important', whiteSpace: 'nowrap', borderBottom: '0.125rem solid #dee2e6' }}
                                >
                                  {data.compositionName}
                                </Tabs.Tab>
                              </Tooltip>
                            ))}
                          </Tabs.List>
                        </Tabs>
                      )}
                      {/* card component */}
                      <Card className='summary-text-area' shadow="sm">
                        <Card.Section className={classes.section} p="lg" style={{ borderBottom: '0px !important' }}>
                          {(segmentedNoteTitle === "" || isloading || isProgressBarVisible) ? (
                            <Group align='center' className='loader-div'> <Loader size="34" /></Group>
                          ) : ((segmentedNoteTitle && segmentedNoteTitle.includes("SOAP")) || (isSegmentedNote && selectedButton)) ? (
                            <>
                              {/* Display SOAP sections (Subject, Objective, Assessment, Plan) */}
                              <RichTextEditorContainer
                                label="Subjective"
                                value={subject}
                                editor={subjectEditor}
                                isCheckActionBtn={isCheckActionBtn}
                                section="Subjective"
                                handleCopy={handleCopy}
                                handleDownload={(label) => handleDownload(label, subject)}
                                copiedSection={copiedSection}
                                isCopyLoading={isCopyLoading}
                                downloadingSection={downloadingSection}
                                isDownloadLoading={isDownloadLoading}
                                isEmailLoading={isProgressBarVisible}
                                handleEmailClick={(label) => handleEmailClick(label, subject)}
                              />
                              <RichTextEditorContainer
                                label="Objective"
                                value={objective}
                                editor={objectiveEditor}
                                isCheckActionBtn={isCheckActionBtn}
                                section="Objective"
                                handleCopy={handleCopy}
                                handleDownload={(label) => handleDownload(label, objective)}
                                copiedSection={copiedSection}
                                isCopyLoading={isCopyLoading}
                                downloadingSection={downloadingSection}
                                isDownloadLoading={isDownloadLoading}
                                isEmailLoading={isProgressBarVisible}
                                handleEmailClick={(label) => handleEmailClick(label, objective)}
                              />
                              <RichTextEditorContainer
                                label="Assessment"
                                value={assessment}
                                editor={assessmentEditor}
                                isCheckActionBtn={isCheckActionBtn}
                                section="Assessment"
                                handleCopy={handleCopy}
                                handleDownload={(label) => handleDownload(label, assessment)}
                                copiedSection={copiedSection}
                                isCopyLoading={isCopyLoading}
                                downloadingSection={downloadingSection}
                                isDownloadLoading={isDownloadLoading}
                                isEmailLoading={isProgressBarVisible}
                                handleEmailClick={(label) => handleEmailClick(label, assessment)}
                              />
                              <RichTextEditorContainer
                                label="Plan"
                                value={plan}
                                editor={planEditor}
                                isCheckActionBtn={isCheckActionBtn}
                                section="Plan"
                                handleCopy={handleCopy}
                                handleDownload={(label) => handleDownload(label, plan)}
                                copiedSection={copiedSection}
                                isCopyLoading={isCopyLoading}
                                downloadingSection={downloadingSection}
                                isDownloadLoading={isDownloadLoading}
                                isEmailLoading={isProgressBarVisible}
                                handleEmailClick={(label) => handleEmailClick(label, plan)}
                              />
                            </>
                          ) : (
                            // Display complete note Textarea for non segmented notes
                            <div className='rich-text-editor'>
                              <CustomTextarea
                                label=""
                                initialValue={parseHTMLToPlainText(summaryNote)}
                                ref={customTextareaRef}
                                isCheckActionBtn={isCheckActionBtn}
                                copyDetails={copyDetails}
                                isloading={isloading}
                                isCopied={isCopied}
                                isDownloading={isDownloading}
                                downloadSummary={downloadSummary}
                                setIsConsentBox={setIsConsentBox}
                                setConsentBoxEvent={setConsentBoxEvent}
                                isEmailLoading={isProgressBarVisible}
                              />
                            </div>
                          )}

                          <Center w="100%" mt="lg" style={{ justifyContent: 'flex-end' }}>
                            {/* entire note action buttons start*/}
                            {(segmentedNoteTitle && segmentedNoteTitle.includes("SOAP")) && (
                              <Group mr={20} className='note-action-btns'>
                                <Tooltip label="Copy the entire note" withArrow>
                                  <Button
                                    size="xs"
                                    className={`btn-primary ${copiedSection === 'all' && 'btn-success'}`}
                                    disabled={isloading}
                                    onClick={() => handleSectCopy({ Subjective: subject, Objective: objective, Assessment: assessment, Plan: plan }, 'all')}
                                  >
                                    {copiedSection === 'all' ? 'Copied' : <IconCopy size={18} />}
                                  </Button>
                                </Tooltip>
                                <Tooltip label="Download the entire note" withArrow>
                                  <Button
                                    size="xs"
                                    disabled={isloading}
                                    className={`btn-primary ${downloadingSection === 'SOAP Download' ? 'btn-success' : ''}`}
                                    onClick={handleDownloadEntireNote}
                                  >
                                    {downloadingSection === 'SOAP Download' ? 'Downloading...' : <IconDownload size={18} />}
                                  </Button>
                                </Tooltip>
                                <Tooltip label="Send the entire note via email" withArrow>
                                  <Button
                                    size="xs"
                                    className="btn-primary"
                                    disabled={isloading}
                                    onClick={() => handleSendEntireNoteEmail()}
                                  >
                                    <IconMail size={18} />
                                  </Button>
                                </Tooltip>
                              </Group>
                            )}
                            {/* entire note action buttons end*/}

                            {/* TODO: Hide the "Regenerate Note" button temporarily.*/}
                            {/* <Tooltip label="Regenerate the selected note with updated content." withArrow position="left">
                              <Button
                                mr="lg"
                                onClick={handleRegenerateClick}
                                className="feedbackBtn"
                                disabled={isloading || segmentedNoteTitle === "" || isProgressBarVisible}
                                sx={{ backgroundColor: '#8dc744' }}
                              >
                                {isloading ? <Loader size={20} color='white' /> : 'Regenerate Note'}

                              </Button>
                            </Tooltip> */}
                            <Tooltip label="Please rate the quality of your note." withArrow position="left">
                              <Button
                                mr="lg"
                                onClick={handleFeedbackClick}
                                className="feedbackBtn"
                                disabled={isloading || segmentedNoteTitle === "" || isProgressBarVisible}
                                sx={{ backgroundColor: '#8dc744' }}
                              >
                                {isloading ? <Loader size={20} color='white' /> : 'Feedback'}

                              </Button>
                            </Tooltip>
                            <Button
                              className="btn-primary"
                              mr="lg"
                              disabled={!isSummaryUpdated || isloading || isProgressBarVisible}
                              onClick={() => {
                                updateSummary();
                              }}
                            >
                              {isloading ? <Loader size={20} color='white' /> : 'Save Notes'}
                            </Button>
                          </Center>
                        </Card.Section>
                      </Card>
                    </Grid.Col>
                  </Grid>
                </Tabs.Panel>
              </Tabs>
            )}
          </Grid.Col>
          <Grid.Col span={12} md={4} mt={8}>
            {summary?.length > 0 && (
              <div style={{ display: isMobile && selectedTab === "transcript" ? 'none' : 'block' }}>
                <TemplateList selectedTemplateNote={getSummary} summary={summary} />
              </div>
            )}
          </Grid.Col>
        </Grid>
      </Box>

      {isAddPatient && (
        <PatientStepper
          isOpen={isAddPatient}
          onClose={() => onCancel()}
          onConfirm={endUnknownSession}
          checkLoader={isCheckNextBtnLoader}
        />
      )}

      <UnknownSessionConfirmBox
        isOpen={unknownSessionConfirm}
        onClose={() => setUnknownSessionConfirm(false)}
        onConfirm={handleConfirmDelete}
        patient={''}
        confirm="Delete"
        cancel="Cancel"
        message={`Are you sure you want to delete this unknown session?`}
        additionalText=""
        isLoader={isDeletingLoader}
      />

      {isConfirmOpen && (
        <ConfirmBox
          isOpen={isConfirmOpen}
          onClose={() => setIsConfirmOpen(false)}
          onConfirm={() => onConfirmExit()}
          content={
            'Please note that confirming this action will completely discard the unsaved changes. Would you like to proceed?'
          }
        />
      )}

      {isGeneratedTranscript && (
        <ConfirmBox
          isOpen={isGeneratedTranscript}
          onClose={() => setIsGeneratedTranscript(false)}
          onConfirm={() => updateGeneratedTranscript()}
          content={`Following notes will be updated ${summary
            ?.map((entry: { compositionName: any }) => entry.compositionName)
            .join(', ')}`}
        />
      )}

      {isConsentBox && (
        <ConfirmBox
          isOpen={isConsentBox}
          onClose={() => setIsConsentBox(false)}
          onConfirm={() => {
            const [event, label, ...contentParts] = consentBoxEvent.split('|');
            const content = contentParts.join('|');
            if (event === 'email') {
              setOpenEmailBox(true);
              setIsConsentBox(false);
              setEmailBoxSoapSection(label);
              setEmailBoxContent(content);
            } else {
              downloadSummary();
              setIsConsentBox(false);
            }
          }}
          content={`Please note that you would need patient's consent to share Patient's PHI data externally.`}
        />
      )}

      {openEmailBox && (
        <EmailBox isOpen={openEmailBox} onClose={() => setOpenEmailBox(false)} documentReferenceId={noteId} defaultEmail={defaultPatientEmail} soapSection={emailBoxSoapSection} summaryNote={summaryNote} content={emailBoxContent} />
      )}

      {popupOpened && (
        <FeedbackRatingPopup
          opened={popupOpened}
          onClose={handlePopupClose}
          onSubmit={handlePopupSubmit}
          noteId={noteId}
        />
      )}

    </>
  );
};

