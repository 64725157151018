import React, { createContext, useContext, ReactNode, useState } from 'react';

interface AppContextProps {
  isConfirmOpen: boolean;
  setIsConfirmOpen: (value: boolean) => void;
  isSessionOn: any;
  setIsSessionOn: (value: boolean) => void;
  redirectionUrl: any;
  setRedirectionUrl: (value: any) => void;
  isSummaryDetailsUpdated: boolean;
  setIsSummaryDetailsUpdated: (value: boolean) => void;
  registerPageHeight: string | number;
  setRegisterPageHeight: (value: any) => void;
  isOldPatientId: string;
  setOldPatientId: (value: string) => void;
  isSessionState: string;
  setSessionState: (value: string) => void;
  sessionTranscripts: string[];
  setSessionTranscripts: (value: any) => void;
  sessionStartDateTime: Date | null;
  setSessionStartDateTime: (value: Date | null) => void;
  isSessionStop: boolean;
  setIsSessionStop: (value: boolean) => void;
  appointMentIdValue: string;
  setAppointMentIdValue: (value: string) => void;
  docRefeId: string;
  setDocRefeId: (value: string) => void;
  isUnknownSessionsRefresh: boolean;
  setUnknownSessionsRefresh: (value: boolean) => void;
  hasSoapSummaryGenerationError: boolean;
  setHasSoapSummaryGenerationError: (value: boolean) => void;
  sessionStatus: string;
  setSessionStatus: (value: string) => void;
  practitionerActiveStatus: string;
  setPractitionerActiveStatus: (value: string) => void;
  patientActiveStatus: string;
  setPatientActiveStatus: (value: string) => void;
  zoomPatientName: string;
  setZoomPatientName: (value: string) => void;
  zoomTranscript: string[];
  setZoomTranscript: (value: any) => void;
  isPatientPopup: boolean;
  setIsisPatientPopup: (value: boolean) => void;
  hideZoomVideo: boolean;
  setHideZoomVideo: (value: boolean) => void;
  patientZoomUrl: string;
  setPatientZoomUrl: (value: string) => void;
  defaultCompositionId: string;
  setDefaultCompositionId: (value: string) => void;
  isSegmentedNote: boolean;
  setIsSegmentedNote: (value: boolean) => void;
  segmentedNoteTitle: string;
  setSegmentedNoteTitle: (value: string) => void;
  isTemplateListBtnDisabled: boolean;
  setTemplateListBtnDisabled: (value: boolean) => void;
  isNavbarVisible: boolean;
  setIsNavbarVisible: (value: boolean) => void;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isSessionOn, setIsSessionOn] = useState(false);
  const [isSummaryDetailsUpdated, setIsSummaryDetailsUpdated] = useState(false);
  const [redirectionUrl, setRedirectionUrl] = useState(undefined);
  const [registerPageHeight, setRegisterPageHeight] = useState<any>('100vh');
  const [isOldPatientId, setOldPatientId] = useState<string>('');
  const [isSessionState, setSessionState] = useState<string>('');
  const [sessionTranscripts, setSessionTranscripts] = useState<any[]>([]);
  const [sessionStartDateTime, setSessionStartDateTime] = useState<Date | null>(null);
  const [isSessionStop, setIsSessionStop] = useState(false);
  const [appointMentIdValue, setAppointMentIdValue] = useState<string>('');
  const [docRefeId, setDocRefeId] = useState<string>('');
  const [isUnknownSessionsRefresh, setUnknownSessionsRefresh] = useState(true);
  const [hasSoapSummaryGenerationError, setHasSoapSummaryGenerationError] = useState(false);
  const [sessionStatus, setSessionStatus] = useState<string>('checked-in');
  const [practitionerActiveStatus, setPractitionerActiveStatus] = useState<string>('true');
  const [patientActiveStatus, setPatientActiveStatus] = useState<string>('true');
  const [zoomPatientName, setZoomPatientName] = useState<string>('');
  const [zoomTranscript, setZoomTranscript] = useState<any[]>([]);
  const [isPatientPopup, setIsisPatientPopup] = useState(false);
  const [hideZoomVideo, setHideZoomVideo] = useState(true);
  const [patientZoomUrl, setPatientZoomUrl] = useState('');
  const [defaultCompositionId, setDefaultCompositionId] = useState('');
  const [isSegmentedNote, setIsSegmentedNote] = useState(false);
  const [segmentedNoteTitle, setSegmentedNoteTitle] = useState('');
  const [isTemplateListBtnDisabled, setTemplateListBtnDisabled] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  return (
    <AppContext.Provider
      value={React.useMemo(
        () => ({
          isConfirmOpen, setIsConfirmOpen, isSessionOn, setIsSessionOn, redirectionUrl, setRedirectionUrl, isSummaryDetailsUpdated, setIsSummaryDetailsUpdated, registerPageHeight, setRegisterPageHeight, isOldPatientId, setOldPatientId, isSessionState, setSessionState, sessionTranscripts, setSessionTranscripts, sessionStartDateTime, setSessionStartDateTime, isSessionStop, setIsSessionStop, appointMentIdValue, setAppointMentIdValue, docRefeId, setDocRefeId, isUnknownSessionsRefresh, setUnknownSessionsRefresh, hasSoapSummaryGenerationError, setHasSoapSummaryGenerationError, sessionStatus, setSessionStatus, practitionerActiveStatus, setPractitionerActiveStatus, patientActiveStatus, setPatientActiveStatus, zoomPatientName, setZoomPatientName, zoomTranscript, setZoomTranscript, isPatientPopup, setIsisPatientPopup,
          hideZoomVideo, setHideZoomVideo, patientZoomUrl, setPatientZoomUrl, defaultCompositionId, setDefaultCompositionId, isSegmentedNote, setIsSegmentedNote, segmentedNoteTitle, setSegmentedNoteTitle, isTemplateListBtnDisabled, setTemplateListBtnDisabled, isNavbarVisible, setIsNavbarVisible
        }),
        [isConfirmOpen, setIsConfirmOpen, isSessionOn, setIsSessionOn, redirectionUrl, setRedirectionUrl, isSummaryDetailsUpdated, setIsSummaryDetailsUpdated, registerPageHeight, setRegisterPageHeight, isOldPatientId, setOldPatientId, isSessionState, setSessionState, sessionTranscripts, setSessionTranscripts, sessionStartDateTime, setSessionStartDateTime, isSessionStop, setIsSessionStop, appointMentIdValue, setAppointMentIdValue, docRefeId, setDocRefeId, isUnknownSessionsRefresh, setUnknownSessionsRefresh, hasSoapSummaryGenerationError, setHasSoapSummaryGenerationError, sessionStatus, setSessionStatus, practitionerActiveStatus, setPractitionerActiveStatus, patientActiveStatus, setPatientActiveStatus, zoomPatientName, setZoomPatientName, zoomTranscript, setZoomTranscript, isPatientPopup, setIsisPatientPopup,
          hideZoomVideo, setHideZoomVideo, patientZoomUrl, setPatientZoomUrl, defaultCompositionId, setDefaultCompositionId, isSegmentedNote, setIsSegmentedNote, segmentedNoteTitle, setSegmentedNoteTitle, isTemplateListBtnDisabled, setTemplateListBtnDisabled, isNavbarVisible, setIsNavbarVisible
        ]
      )}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
